import { ReactNode } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ZDialog } from '@zep/ui';
import { cn } from '@zep/utils';
import { useTranslation } from 'next-i18next';
export type CustomAlertProps = {
  type: 'confirm' | 'alert' | 'danger';
  title: ReactNode;
  content?: ReactNode;
  cancelText?: ReactNode;
  cancelAction?: () => void;
  confirmText?: ReactNode;
  confirmAction?: () => void;
  useOutSideClick?: boolean;
};
export function _CustomAlert(props: CustomAlertProps) {
  const {
    t
  } = useTranslation();
  const {
    type = 'alert',
    useOutSideClick = true
  } = props;
  const modal = useModal();
  const {
    Dialog,
    DialogPanel,
    DialogHeader,
    DialogContent,
    DialogTitle,
    DialogFooter
  } = ZDialog;
  return <Dialog maxWidth={'320px'} open={modal.visible} onClose={() => {
    if (!useOutSideClick) return false;
    modal.remove();
  }} data-sentry-element="Dialog" data-sentry-component="_CustomAlert" data-sentry-source-file="CustomAlert.tsx">
      <DialogPanel data-sentry-element="DialogPanel" data-sentry-source-file="CustomAlert.tsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="CustomAlert.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="CustomAlert.tsx">{props.title}</DialogTitle>
        </DialogHeader>
        <DialogContent className={'h-auto'} data-sentry-element="DialogContent" data-sentry-source-file="CustomAlert.tsx">
          <div className="text-start text-[14px] font-normal leading-normal text-gray-700">
            {props.content}
          </div>
        </DialogContent>
        <DialogFooter className="flex items-center gap-xs pt-[24PX]" data-sentry-element="DialogFooter" data-sentry-source-file="CustomAlert.tsx">
          {type !== 'alert' && <button className="h-[40px] flex-1 rounded bg-gray-100 text-[14px] font-semibold leading-normal text-gray-600" onClick={() => {
          props.cancelAction && props.cancelAction();
          modal.remove();
        }}>
              {props.cancelText || t('global.cancel.button', '취소')}
            </button>}
          <button className={cn('flex-1 h-[40px] text-[14px] font-semibold leading-[1.4] rounded', type === 'danger' ? 'text-[#ff5353] bg-[#fff2f7] border-black/[3%]' : 'text-white bg-primary')} onClick={() => {
          modal.resolve();
          // // TODO:: 확인 버튼 이벤트를 resolve 할지 해당 액션을 할지 정해져야한다
          props.confirmAction?.();
          modal.remove();
        }}>
            {props.confirmText || t('global.confirm.button', '확인')}
          </button>
        </DialogFooter>
      </DialogPanel>
    </Dialog>;
}
export const CustomAlert = NiceModal.create(_CustomAlert);